.Contact a {
	position: relative;
	display: block;
	text-decoration: none;
	text-decoration: none;
	color: #66ff66;
	font-size: 24px;
}

.Icon {
	padding-right: 10px;
}
